<main>
  <div class="position-relative">
    <!-- shape Hero -->
    <section class="section section-lg section-shaped">
      <div class="shape shape-style-1 shape-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container d-flex">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-6">
              <h1 class="display-3  text-white">Qu'est-ce que GPRA&nbsp;?</h1>
              <p class="lead text-white">GPRA est le logiciel de la maîtrise d’ouvrage de nouvelle génération, indispensable pour piloter efficacement votre activité en quelques clics. Il a été pensé afin de faciliter le travail de tous les intervenants de vos projets. Que vous soyez sur le terrain ou au bureau, GPRA vous accompagne sur tout support afin de faciliter le suivi de votre activité et de vos tâches quotidiennes</p>
            </div>
            <div class="col-lg-6">
              <img src="./assets/img/theme/bg.svg" class="img-fluid floating">
            </div>            
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-bggpra" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>
    <!-- 1st Hero Variation -->
  </div>

  <section class="section">
    <div class="container">
      <h3 class="display-3">Pourquoi utiliser GPRA&nbsp;?</h3>
      <div class="row row-grid align-items-center mb-lg-5">
        <div class="col-md-6 order-md-1">
          <p>Voici une liste non exhaustive des fonctionnalités proposées par GPRA</p>
          <img src="./assets/img/theme/all.svg" class="img-fluid floating">
        </div>
        <div class="col-md-6 order-md-2 mob-y--100">
          <div class="">
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-calendar-grid-58"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0"><b>PLANIFIER</b>
                      <p>Grâce aux modèles de planning définis en amont, gagnez du temps lors de leur mise en oeuvre. Vous n'aurez qu'à les personnaliser pour qu'ils correspondent aux besoins de votre projet.</p>
                    </h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-send"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0"><b>SUIVRE</b>
                      <p>Le module planning recale automatiquement vos dates en cas de retard et calcule pour vous les éventuels écarts.</p>
                    </h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-controller"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0"><b>GERER ET ANTICIPER</b>
                      <p>GPRA vous informe en temps réel de l'avancé de vos projets grâce à son système de notifications. Vous ne serez jamais pris de court face aux événements qui approchent.</p>
                    </h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-chart-bar-32"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0"><b>EXPLOITER ET PREVOIR</b>
                      <p>Nos rapports entièrement personnalisés vous permettent d'extraire et de traiter l'ensemble des données saisies dans l'outil mais aussi d'établir des prévisions précises sur votre activité.</p>
                    </h6>
                  </div>
                </div>
              </li>              
            </ul>
          </div>
        </div>
      </div>

      <h3 class="display-3">Un aperçu de l'application&nbsp;?</h3>
      <div class="row justify-content-between align-items-center">
        <div class="col-12 mb-lg-auto">
          <div>
            <p class="text-dark text-justify">Grâce au planning de Gantt de GPRA et ses notifications, vous serez toujours tenus informés des événements à venir. Vous pouvez également en un coup d’œil visualiser vos points de vigilances et les corriger de façon pro-active.
              <br/>
              Ses rapports entièrement personnalisables vous permettront d’analyser votre activité afin de toujours être le plus efficace possible.
            </p>
            <p class="text-dark text-justify">L'outil est paramétré selon vos besoins afin de s’inscrire parfaitement dans vos processus de travail et ainsi garantir une prise en main rapide et efficace.</p>
          </div>
          <div class="rounded shadow-lg overflow-hidden">
            <ngb-carousel>
                <ng-template ngbSlide>
                  <h5>Une solution nomade (SaaS)</h5>
                  <img class="img-fluid" src="assets/img/gpra-app/login.png" alt="Authentification">
                </ng-template>
                <ng-template ngbSlide>
                  <h5>Un profil utilisateur complet</h5>
                  <img class="img-fluid" src="assets/img/gpra-app/profile.png" alt="Mon profil">
                </ng-template>
                <ng-template ngbSlide>
                  <h5>Une page d'accueil personnalisable</h5>
                  <img class="img-fluid" src="assets/img/gpra-app/accueil.png" alt="Ma page d'accueil">
                </ng-template>
                <ng-template ngbSlide>
                  <h5>Des rapports d'exploitation en temps réel</h5>
                  <img class="img-fluid" src="assets/img/gpra-app/tdb.png" alt="Tableau de bord">
                </ng-template>
                <ng-template ngbSlide>
                  <h5>Un planning détaillé qui correspond parfaitement à vos projets</h5>
                  <img class="img-fluid" src="assets/img/gpra-app/planning.png" alt="Aperçu des planning">
                </ng-template>
            </ngb-carousel>
          </div>
        </div>
      </div>
       
    </div>
  </section>
</main>
