<main>
  <div class="position-relative">
    <!-- shape Hero -->
    <section class="section section-lg section-shaped">
      <div class="shape shape-style-1 shape-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <!-- <span></span> -->
        <!-- <span></span> -->
      </div>
      <div class="container d-flex">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-6 order-md-1">
              <h1 class="display-3 text-white">Nous contacter&nbsp;?</h1>
              <p class="lead text-white text-left">Nous restons à votre entière disposition pour tout renseignement.
                <br/>
                Nous pouvons également nous déplacer pour vous faire une démonstration de l’outil.
                <br/>
                Téléchargez <a href={{urlPlaquette}} title="Téléchargez notre plaquette" target="_blank" rel="noopener noreferrer">ici</a> notre plaquette.
                <br/>
              </p>

              <p class="lead text-white text-left">
                <b>Nous contacter directement&nbsp;:</b>
                <br/>
                <a href="mailto:{{mailTo}}">contact@gpra-france.fr</a>
              </p>              
            </div>
            <div class="col-lg-6 order-md-2">
              <div class="card bg-gradient-secondary shadow">
                <div class="card-body p-lg-5">
                  <p class="mt-0">Ou écrire ici votre message</p>
                  <form method="post" role="form" enctype="multipart/form-data" (ngSubmit)="sendMail()" [formGroup]="contactForm" novalidate>
                    <div class="form-group mt-2" [ngClass]="{'focused':focus1===true}">
                      <div class="input-group input-group-alternative" [ngClass]="{'has-danger':(contactForm.get('Nom').touched || submitted) && contactForm.get('Nom').invalid}">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-badge"></i></span>
                        </div>
                        <input class="form-control" formControlName="Nom" placeholder="Saisir votre nom" type="text" (focus)="focus1=true" (blur)="focus1=false">
                      </div>
                    </div>
                    <div class="form-group mt-2" [ngClass]="{'focused':focus5===true}">
                      <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-badge"></i></span>
                        </div>
                        <input class="form-control" formControlName="Prenom" placeholder="Saisir votre prénom" type="text" (focus)="focus5=true" (blur)="focus5=false">
                      </div>
                    </div>                    
                    <div class="form-group" [ngClass]="{'focused':focus2===true}">
                      <div class="input-group input-group-alternative" [ngClass]="{'has-danger':(contactForm.get('Email').touched || submitted) && contactForm.get('Email').invalid}">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                        </div>
                        <input class="form-control" formControlName="Email" placeholder="Saisir votre adresse email" type="text" (focus)="focus2=true" (blur)="focus2=false">
                      </div>
                    </div>   
                    <div class="form-group" [ngClass]="{'focused':focus3===true}">
                      <div class="input-group input-group-alternative" [ngClass]="{'has-danger':(contactForm.get('Societe').touched || submitted) && contactForm.get('Societe').invalid}">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-ruler-pencil"></i></span>
                        </div>
                        <input class="form-control" formControlName="Societe" placeholder="Renseigner votre société" type="text" (focus)="focus3=true" (blur)="focus3=false">
                      </div>
                    </div>                                 
                    <div class="form-group" [ngClass]="{'focused':focus4===true}">
                      <div class="input-group input-group-alternative" [ngClass]="{'has-danger':(contactForm.get('Fonction').touched || submitted) && contactForm.get('Fonction').invalid}">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-tie-bow"></i></span>
                        </div>
                        <input class="form-control" formControlName="Fonction" placeholder="Quelle est votre fonction ?" type="email" (focus)="focus4=true" (blur)="focus4=false">
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <textarea class="form-control form-control-alternative" formControlName="InfoSup" rows="4" cols="80" placeholder="Saisir ici votre message..."></textarea>
                    </div>
                    <div class="form-group" [ngClass]="{'focused':focus4===true}">
                      <div class="input-group input-group-alternative" [ngClass]="{'has-danger':(contactForm.get('Fonction').touched || submitted) && contactForm.get('Fonction').invalid}">
                        <div class="input-group-prepend">
                          <p class="mt-0">Aucune donnée à caractère personnel n'est conservée par ce site.</p>
                        </div>
                      </div>
                    </div>


                    <div class="row" style="justify-content: center;">
                      <re-captcha formControlName="RecaptCha" (resolved)="resolved($event)" siteKey={{captchaKey}}></re-captcha>
                    </div>

                    <div style="text-align: center;" class="mt-2">
                      <button type="submit" class="btn btn-sm btn-success btn-round btn-lg">Envoyer</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-bggpra" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>
    <!-- 1st Hero Variation -->
  </div>

</main>
