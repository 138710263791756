<nav id="navbar-main"
    class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--pinned">
    <div class="container">
        <a class="navbar-brand mr-lg-5" [routerLink]="['/actus']">
            <img src="./assets/img/brand/GPRA-white.png">
        </a>
        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
            <div class="navbar-collapse-header">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <a [routerLink]="['/actus']">
                            <img src="./assets/img/brand/GPRA-white.png">
                        </a>
                    </div>
                    <div class="col-6 collapse-close">
                        <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </div>
            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                <!-- <li class="nav-item">
                    <a [routerLink]="['/actus']" class="nav-link no-caret" [ngClass]="{'active': getPath()==='/actus'}">
                        <span class="nav-link-inner--text">Informations</span>
                    </a>
                </li> -->
                <li class="nav-item">
                    <a [routerLink]="['/gpra']" class="nav-link no-caret"
                        [ngClass]="{'active': getPath()==='/gpra' || getPath() === ''}">
                        <span class="nav-link-inner--text">GPRA</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/aboutus']" class="nav-link no-caret"
                        [ngClass]="{'active': getPath()==='/aboutus'}">
                        <span class="nav-link-inner--text">Qui sommes nous ?</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/contact']" class="nav-link no-caret"
                        [ngClass]="{'active': getPath()==='/contact'}">
                        <span class="nav-link-inner--text">Contact</span>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="https://www.linkedin.com/company/gpra-france"
                        target="_blank" data-toggle="tooltip" title="Consultez-nous sur LinkedIn">
                        <i class="fa fa-linkedin-square"></i>
                        <span class="nav-link-inner--text d-lg-none">LinkedIn</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>