<footer class="footer" [ngClass]="{'has-cards': getPath()!=='/user-profile' && getPath()!=='/register' && getPath()!=='/login'}">
  <div class="container">
    <hr>
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          &copy; {{test | date: 'yyyy'}}
          <span>Gira Consulting</span>.
        </div>
      </div>
      <div class="col-md-6">
      </div>
    </div>
  </div>
</footer>
