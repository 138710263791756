<main>
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section section-lg section-shaped">
            <div class="shape shape-style-1 shape-default">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <!-- <span></span> -->
                <!-- <span></span> -->
            </div>
            <div class="container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6 order-md-1">
                            <h1 class="display-3 text-white">Un petit historique&nbsp;?</h1>
                            <img src="./assets/img/theme/qui-sommes-nous.png" class="img-fluid floating">
                        </div>
                        <div class="col-lg-6 order-md-2">
                            <p class="lead text-white text-justify">Courant 2019 suite à différents contacts avec des
                                acteurs du secteur de l’Habitat nous avons constaté que ces professionnels cherchaient
                                un outil conforme à l’évolution de leur métier et des technologies.
                                <br />
                                Début 2020 après étude de marché et réflexions, Gira consulting et ses nouveaux associés
                                ont donc décidés d'unir leurs compétences pour développer une solution multi-canal en se
                                basant sur les besoins des utilisateurs et sur les nouvelles technologies (SaaS).
                                Nous avons donc lancé en 2020 le développement de l'outil GPRA de Gestion de Plannings,
                                de Reporting et d'Alerte dédié aux professionnels de l'Habitat.
                                <br />
                                Notre politique est de faire évoluer en permanence cet outil par rapport aux nouveaux
                                besoins des utilisateurs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SVG separator -->
            <div class="separator separator-bottom separator-skew">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-bggpra" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </section>
        <!-- 1st Hero Variation -->
    </div>

    <section class="section">
        <div class="container">
            <div class="row" style="justify-content: center;">
                <div class="col-lg-5">
                    <h2 class="display-3">Qui sommes nous&nbsp;?</h2>
                    <p class="lead text-muted"></p>
                </div>
            </div>
            <div class="row" style="justify-content: center;">
                <div class="col-md-4 col-lg-4 mb-5 mb-lg-0">
                    <div class="px-2">
                        <img src="./assets/img/theme/pdg.png"
                            class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            style="width: 200px;height: 200px; object-fit: cover;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">Bernard Giraud</span>
                                <small class="h6 text-muted"><b>Président Directeur Général</b></small>
                                <p class="text-dark text-justify">Président Directeur Général de GPRA et Gira consulting
                                    avec plus de 20 ans d’expérience dans le domaine de l'informatique.</p>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 mb-5 mb-lg-0">
                    <div class="px-4">
                        <img src="./assets/img/theme/cto.png"
                            class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            style="width: 200px;height: 200px; object-fit: cover;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">Cyril Ferreira</span>
                                <small class="h6 text-muted"><b>Directeur technique et CTO</b></small>
                                <p class="text-dark text-justify">Responsable fonctionnel et du développement de l’outil
                                    avec plus de 8 ans d’expérience dans le domaine du développement informatique lié
                                    aux métiers de l’Habitat.</p>
                            </h5>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 col-lg-4 mb-5 mb-lg-0">
                    <div class="px-2">
                        <img src="./assets/img/theme/rhs.png"
                            class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            style="width: 200px;height: 200px; object-fit: cover;">
                        <div class="pt-4 text-center">
                            <h5 class="title">
                                <span class="d-block mb-1">Jenny Rasoza</span>
                                <small class="h6 text-muted"><b>Directrice des ressources Humaines</b></small>
                                <p class="text-dark text-justify">Directrice des ressources Humaines avec plus de 10 ans
                                    d’expérience dans le domaine du numérique.</p>
                            </h5>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</main>