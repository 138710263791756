import { Component, OnInit, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { FormBase } from '../../form/formBase';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})

export class ContactComponent extends FormBase implements OnInit {
    test : Date = new Date();
    urlPlaquette: string = environment.urlPlaquette;   
    captchaKey: string = environment.captchaKey;
    mailTo: string = environment.mailTo;

    focus1;
    focus2;
    focus3;
    focus4;
    focus5;
    focus6;
    submitted;
    contactForm;

    objectMail = {
        body1: "",
        body2: "",
        recipient: ""
    }

    recaptchaResponse: any[];

    constructor(
        private httpService: HttpService,
        private fb: FormBuilder
    ) {
        super()
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.contactForm = this.fb.group({
            Nom: ['', [Validators.required]],
            Prenom: [''],
            Societe: ['', [Validators.required]],
            Fonction: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.email]],
            InfoSup: [''],
            RecaptCha: ['', [Validators.required]]
        });
        this.validationErrors = {

        };
        this.errors = { Nom: '', Societe: '', Fonction: '', Email: '', RecaptCha: '' };
        this.contactForm.valueChanges.subscribe(value => {
            this.validateControls();
        });
    }

    sendMail() {
        this.submitted = true;
        if(!this.contactForm.valid) return false;

        this.validateControlsBeforeSave(this.contactForm);

        const formData = new FormData();
        this.objectMail.body1 = "Nouvelle demande de contact : \r\n" +
            "- Nom : " + this.contactForm.controls["Nom"].value + " \r\n" +
            "- Prénom : " + this.contactForm.controls["Prenom"].value + " \r\n" +
            "- Société : " + this.contactForm.controls["Societe"].value + " \r\n" +
            "- Fonction : " + this.contactForm.controls["Fonction"].value + " \r\n" +
            "- Mail : " + this.contactForm.controls["Email"].value + " \r\n" +
            "- Informations supplémentaires : " + this.contactForm.controls["InfoSup"].value;
        this.objectMail.body2 = "Bonjour, \r\n\r\n" +
            "Votre demande de renseignements au sujet du progiciel GPRA a bien été prise en compte. \r\n\r\n" +
            "Nos équipes vous recontacteront dans les plus brefs délais.\r\n\r\n" +
            "Bien cordialement, \r\n" +
            "L'équipe GPRA";
        this.objectMail.recipient = this.contactForm.controls["Email"].value;

        formData.append('body1', this.objectMail.body1);
        formData.append('body2', this.objectMail.body2);
        formData.append('recipient', this.objectMail.recipient);
        formData.append('g-recaptcha-response', this.contactForm.controls["RecaptCha"].value);

        if (this.objectMail && this.objectMail.body1 != "") {
            this.httpService.sendMail(this.objectMail, formData).then(res => {
                //this.initForm();
            }, err => {
                console.log("error send mail", err);
            })
        }
    }

    resolved(captchaResponse: any[]) {

    }
}
