import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    hostName = 'https://gpra-france.fr';
    defaultTitle = 'GPRA-France';
    defaultDesc = 'La Gestion du Pilotage des Reportings des Alertes';

    constructor(
        @Inject(DOCUMENT) private dom,
        private titleSvc: Title,
        private metaSvc: Meta) { }

    updateCanonicalUrl(url) {
        const head = this.dom.getElementsByTagName('head')[0];
        var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
        if (element == null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        if (url)
            element.setAttribute('href', this.hostName + url);
        else
            element.setAttribute('href', this.hostName);
    }

    updateTitle(title) {
        if (title)
            this.titleSvc.setTitle(title);
        else
            this.titleSvc.setTitle(this.defaultTitle);
    }

    updateDesc(desc) {
        if (desc)
            this.metaSvc.updateTag({ name: 'description', content: desc });
        else
            this.metaSvc.updateTag({ name: 'description', content: this.defaultDesc });
    }
}
