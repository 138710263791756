import { FormGroup } from '@angular/forms';

export class FormBase {

    public model: any;

    public reactiveForm: FormGroup;
    public errors: any;
    public validationErrors: any;

    validateControls(group: FormGroup = this.reactiveForm) {
        Object.keys(group.controls).forEach((key: string) => {
            this.errors[key] = '';
            const control = group.get(key);
            if (control.invalid && (control.touched || control.dirty)) {
                for (const errorKey in control.errors) {
                    if (errorKey) {
                        this.errors[key] = this.validationErrors[key][errorKey];
                    }
                }
            }
        });
    }

    validateControlsBeforeSave(group: FormGroup = this.reactiveForm) {
        Object.keys(group.controls).forEach((key: string) => {
            this.errors[key] = '';
            const control = group.get(key);
            if (control.invalid) {
                for (const errorKey in control.errors) {
                    if (errorKey) {
                        this.errors[key] = this.validationErrors[key][errorKey];
                    }
                }
            }
        });
    }
}
