import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-actus',
    templateUrl: './actus.component.html',
    styleUrls: ['./actus.component.scss']
})
export class ActusComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };
    actus: Actus[];

    focus;
    focus1;
    closeResult: string;

    constructor(
        private modalService: NgbModal,
        private api: HttpService
    ) { }

    ngOnInit() {
        this.getActus();
    }

    getActus() {
        this.api.getActus().subscribe((result: any) => {
            this.actus = result.data.sort((a1, a2) => a1['id'] <= a2['id'] ? 1 : -1);
        }, (error) =>
        {
            console.log(error);
        });
    }    

    open(content, type, modalDimension) {
        if (modalDimension === '' && type === 'photos') {
          this.modalService.open(content, { windowClass: 'modal-success', centered: true, size: 'lg' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {

          });
        }
    }    

    getPathImage(filename: string) {
        return this.api.uriImage + filename;
    }
}

export interface Actus {
    Id: number;
    title: string;
    description: string;
    createdAt: Date,
    medias: any
}

