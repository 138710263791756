import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

export interface Articles {
    id: string;
    name: string;
    description: string;
    prix: number;
    src: string;
    visible: number
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    uri = "https://gpra-france.fr/admin/api/";
    uriImage = "https://gpra-france.fr/admin/api/uploads/";

    constructor(
        private http: HttpClient
        ) {
    }

    public getoffres() {
        return this.http
            .get<any[]>("assets/mocks/offres.json")
            .pipe(map(res => res as any));
    }

    public sendMail(request, formData) {
        return fetch(`${this.uri}mail.php`, {
            method: 'POST',
            body: formData
        }).then(response => {
            console.log(response);
        });
    }

    // **********************************************************
    // Gestion des actualités
    // **********************************************************
    getActus() {
        return this.http.get(`${this.uri}actus/read`);
    }

};
