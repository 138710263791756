import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ContactComponent } from './views/contact/contact.component';
import { GpraComponent } from './views/gpra/gpra.component';
import { AboutusComponent } from './views/aboutus/aboutus.component';
import { ActusComponent } from './views/actus/actus.component';
import { NavbarComponent } from './views/shared/navbar/navbar.component';
import { FooterComponent } from './views/shared/footer/footer.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { HttpService } from './services/http.service';
import { SeoService } from './services/seo.service';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    GpraComponent,
    AboutusComponent,
    NavbarComponent,
    FooterComponent,
    ActusComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [FormBuilder, HttpService, SeoService, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
