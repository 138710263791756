<main>
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section section-lg section-shaped">
            <div class="shape shape-style-1 shape-default">
                <span class="span-150"></span>
                <span class="span-50"></span>
                <span class="span-50"></span>
                <span class="span-75"></span>
                <span class="span-100"></span>
                <span class="span-75"></span>
                <span class="span-50"></span>
                <span class="span-100"></span>
                <span class="span-50"></span>
                <span class="span-100"></span>
            </div>
            <div class="container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3  text-white">Informations sur l'outil</h1>
                            <p class="lead text-white">La solution GPRA évolue en permanence !</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="./assets/img/theme/blog.svg" class="img-fluid floating">
                        </div>
                    </div>
                </div>
            </div>
            <!-- SVG separator -->
            <div class="separator separator-bottom separator-skew">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-bggpra" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </section>
        <!-- 1st Hero Variation -->
    </div>

    <section class="section pt-lg-0 section-actus">
        <div class="container">
            <div class="row justify-content-center row-grid">
                <div class="col-lg-12" *ngIf="actus !== null && actus !== undefined && actus.length == 0"
                    style="text-align: center;">
                    Aucune information pour le moment.
                </div>
                <div *ngFor="let actu of actus;" class="col-lg-4 pb-lg-2">
                    <div class="card shadow border-0 box-actus">
                        <div class="card-body">
                            <div class="icon icon-shape icon-shape-success rounded-circle mb-4">
                                <i class="ni ni-bell-55"></i>
                            </div>
                            <h6 class="text-success"><b>{{actu.title}}</b></h6>
                            <!-- <div>
                                <span class="badge badge-pill badge-success">{{actu.createdAt | date: 'dd MMMM
                                    yyyy'}}</span>
                            </div> -->
                            <p class="description mt-3">{{actu.description}}</p>

                            <div *ngIf="actu.medias.count > 0">
                                <button class="btn btn-sm btn-block btn-success mb-0"
                                    (click)="open(classic2, 'photos', '')">
                                    Voir les photos
                                </button>
                                <ng-template #classic2 let-c="close" let-d="dismiss">
                                    <div class="modal-content bg-gradient-success">
                                        <div class="modal-header">
                                            <h6 class="modal-title" id="modal-title-notification">Photo(s) de
                                                {{actu.title}}</h6>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                                (click)="d('Cross click')">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="rounded shadow-lg">
                                                <ngb-carousel>
                                                    <ng-template *ngFor="let media of actu.medias.data; let i = index"
                                                        ngbSlide>
                                                        <img class="img-fluid" src="{{getPathImage(media.name)}}"
                                                            alt="...">
                                                    </ng-template>
                                                </ngb-carousel>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>