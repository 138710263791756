import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ActusComponent } from './views/actus/actus.component';
import { AboutusComponent } from './views/aboutus/aboutus.component';
import { ContactComponent } from './views/contact/contact.component';
import { GpraComponent } from './views/gpra/gpra.component';

const routes: Routes = [
    {
        path: 'actus',
        component: ActusComponent,
        pathMatch: "full",
        data: {
            title: 'GPRA-France Nouveautés',
            description: 'Premier trimestre 2020, lancement du chantier GPRA. Sortie de la version V1.0 de GPRA',
            canonical: '/actus'
        }
    },
    {
        path: 'gpra',
        component: GpraComponent,
        pathMatch: "full",
        data: {
            title: 'GPRA-France La Gestion du Pilotage des Reportings des Alertes',
            description: 'GPRA est le logiciel de la maîtrise d’ouvrage de nouvelle génération, indispensable pour piloter efficacement votre activité en quelques clics',
            canonical: '/gpra'
        }
    },
    {
        path: 'aboutus',
        component: AboutusComponent,
        pathMatch: "full",
        data: {
            title: 'GPRA-France Qui sommes nous?',
            description: 'Nous avons donc lancé en 2020 le développement de l\'outil GPRA de Gestion de Plannings, de Reporting et d\'Alerte dédié aux professionnels de l\'Habitat',
            canonical: '/aboutus'
        }
    },
    {
        path: 'contact',
        component: ContactComponent,
        pathMatch: "full",
        data: {
            title: 'GPRA-France Nous contacter',
            description: 'Nous restons à votre entière disposition pour tout renseignement : contact@gpra-france.fr',
            canonical: '/contact'
        }
    },
    {
        path: '**',
        component: GpraComponent,
        pathMatch: "full",
        data: {
            title: 'GPRA-France La Gestion du Pilotage des Reportings des Alertes',
            description: 'GPRA est le logiciel de la maîtrise d’ouvrage de nouvelle génération, indispensable pour piloter efficacement votre activité en quelques clics',
            canonical: '/'
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: false
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
